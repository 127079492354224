import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { useState } from "react";

export interface CopyableTextFieldProps {
  label: string;
  value: string;
}

export function CopyableTextField(props: CopyableTextFieldProps): JSX.Element {
  const { label, value } = props;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  function handleCopy(): void {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  return (
    <TextField
      label={label}
      value={value}
      fullWidth={true}
      variant="filled"
      size="small"
      InputProps={{
        readOnly: true,
        endAdornment: (
          <Tooltip title={isCopied ? "Copied!" : "Copy"}>
            <IconButton onClick={handleCopy} edge="end">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
        sx: {
          fontSize: "0.8rem",
        },
      }}
      sx={{
        backgroundColor: "lightgray",
      }}
    />
  );
}
