import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ShiftInviteStepper } from "../Stepper";
import { NearbyWorkerSearchResult } from "src/containers/Shift/ShiftInvites/Autocomplete/useGetNearbyWorkers";
import { ShiftInviteProvider } from "../Context/shiftInviteContext";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ShiftInviteDialogProps {
  workerType: string;
  shiftInfo: {
    start: string;
    end: string;
    shiftType: string;
    timezone?: string;
  };
  modalState: UseModalState;
  initialActiveState?: number;
  initialSelectedWorker?: NearbyWorkerSearchResult;
}

export function ShiftInviteDialog(props: ShiftInviteDialogProps): JSX.Element {
  const { modalState, workerType } = props;

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          modalState.closeModal();
        }
      }}
      aria-labelledby="shift-invite-dialog-title"
      aria-describedby="shift-invite-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle>Invite a Professional</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => modalState.closeModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <ShiftInviteProvider agentReq={workerType}>
          <ShiftInviteStepper {...props} />
        </ShiftInviteProvider>
      </DialogContent>
    </Dialog>
  );
}
