import { Badge, SxProps } from "@mui/material";
import { useLocalStorage } from "usehooks-ts";
import { HIRE_WORKERS_ALERT_VIEW_COUNT, HIRE_WORKERS_PAGE_VIEW_COUNT } from "../utils/constant";
import { Text } from "@clipboard-health/ui-react";

interface HireProfessionalsNewBadgeProps {
  customSx?: SxProps;
}

export function HireProfessionalsNewBadge(props: HireProfessionalsNewBadgeProps) {
  const { customSx } = props;
  const [hireWorkersViewCount] = useLocalStorage(HIRE_WORKERS_PAGE_VIEW_COUNT, 0);
  const [alertViewCount] = useLocalStorage(HIRE_WORKERS_ALERT_VIEW_COUNT, 0);

  const shouldShowAlert = (hireWorkersViewCount ?? 0) <= 5 && (alertViewCount ?? 0) <= 10;

  if (!shouldShowAlert) {
    return null;
  }

  return (
    <Badge
      color="success"
      badgeContent={
        <Text variant="caption" color="white" sx={{ fontSize: "0.5rem" }}>
          NEW
        </Text>
      }
      variant="standard"
      sx={{ ...customSx }}
    />
  );
}
