import { useEffect, useState, useRef } from "react";
import { DEFAULT_DEBOUNCE_RATE_IN_MS } from "./useDebounce";

// https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-debounced-value/use-debounced-value.ts
export function useDebouncedValue<T = unknown>(
  value: T,
  wait = DEFAULT_DEBOUNCE_RATE_IN_MS,
  options = { leading: false }
) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const mountedRef = useRef(false);
  const timeoutRef = useRef<number>();
  const cooldownRef = useRef(false);

  const cancel = () => window.clearTimeout(timeoutRef.current);

  useEffect(() => {
    if (mountedRef.current) {
      if (!cooldownRef.current && options.leading) {
        cooldownRef.current = true;
        setDebouncedValue(value);
      } else {
        cancel();
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false;
          setDebouncedValue(value);
        }, wait);
      }
    }
  }, [value, options.leading, wait]);

  useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, []);

  return [debouncedValue, cancel] as const;
}
