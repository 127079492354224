import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Skeleton } from "antd";
import {
  Rating,
  RatingPerformanceForm,
} from "src/components/WorkerRatingCarouselModal/RatingPerformanceForm";
import {
  convertToWorkerReviewState,
  WorkerWithRatingAndLastShift,
} from "src/components/WorkerRatingCarouselModal/types";
import { useWorkerExclusions } from "src/components/WorkerRatingCarouselModal/useWorkerExclusions";
import { useWorkersForRating } from "src/components/WorkerRatingCarouselModal/useWorkersForRating";
import {
  createExclusion,
  createWorkerReview,
  deleteExclusion,
  ExclusionForList,
  ExclusionForView,
  WorkerReviewDetails,
} from "src/api/workerReview";
import {
  captureWorkerReviewSubmittedEvent,
  doesRatingMakeWorkerFavorite,
  getDNRAction,
  WORKER_REVIEW_DNR_REASON,
} from "src/components/WorkerRatingPerformanceModal/helpers";
import {
  DnrAction,
  WhoCalledRatingPerformanceModal,
  WorkerReviewMode,
} from "src/components/WorkerRatingPerformanceModal/workerReview.types";
import { ActiveTab } from "src/containers/workers/logs";
import { Button } from "src/designsystem/Button/Button";
import { useState } from "react";
import { StyledModal, Wrapper } from "./styles";
import { getSubmitText, RatingPerformanceMode } from "./utils";
import { ViewRatingPerformance } from "./ViewRatingPerformance";
import { Footer } from "./WorkerRatingPerformance.styles";
import { handleWorkerRatingErrorMessageDisplay } from "./workerRatingErrorHandler";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";
import { resetPreferredWorkerQueries } from "@src/api/workers";
import { useHistory } from "react-router-dom";
import { routes } from "@src/utils/routes";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { logEvent } from "@src/appV2/lib/analytics";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import useRateLimiter from "@src/appV2/lib/RateLimit/useRateLimiter";
import { useSession } from "@src/appV2/Auth/api/useSession";

interface WorkerRatingPerformanceModalProps {
  visible: boolean;
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
  timezone: string;
  actionBy: string;
  trackData: {
    activeTab?: ActiveTab;
    whoCalledMe: WhoCalledRatingPerformanceModal;
  };
  onClose: () => void;
  successCallback?: (
    workerReview: WorkerReviewDetails,
    exclusion: ExclusionForView | undefined
  ) => void;
}

export function WorkerRatingPerformanceModal({
  visible,
  workerId,
  workplaceId,
  workplaceUserId,
  timezone,
  actionBy,
  trackData,
  onClose,
  successCallback,
}: WorkerRatingPerformanceModalProps) {
  const history = useHistory();
  const isFacilityRulesAiEnabled = useCbhFlag(CbhFeatureFlag.ROLLOUT_QUIZ_V2_FACILITY_RULES_AI, {
    defaultValue: false,
  });
  const [mode, setMode] = useState<RatingPerformanceMode>("view");
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<WorkerWithRatingAndLastShift>();
  // Use this state for analytics purpose to track rating changes
  const [initialFormState, setInitialFormState] = useState<WorkerWithRatingAndLastShift>();

  const TWO_WEEKS_IN_MS = 14 * 24 * 60 * 60 * 1000;
  const { user } = useSession();

  const attemptRateLimitedAction = useRateLimiter({
    actionKey: `show-facility-rules-alert-on-drn-or-rating-${user?._id}`,
    delayInMilliSeconds: TWO_WEEKS_IN_MS,
    maxCalls: 1,
  });

  const { isLoading: workersForRatingLoading, invalidateCache: invalidateCacheWorkersForRating } =
    useWorkersForRating(workplaceId, [workerId], {
      onSuccess(resp) {
        const workerForRating = resp[0];
        if (!workerForRating.rating) {
          setMode("edit");
        }

        const workerState = {
          worker: {
            userId: workerForRating.userId,
            name: workerForRating.name,
            avatarUrl: workerForRating.profileImageUrl,
          },
          lastShift: workerForRating.lastShift,
          // In workers carousel, we don't prefill the existing rating value
          // a facility user has to select values from empty state
          rating: workerForRating.rating,
        };
        setFormState(workerState);
        setInitialFormState(workerState);
      },
    });

  const {
    isLoading: exclusionsLoading,
    data: exclusionsData,
    invalidateCache: invalidateCacheWorkerExclusions,
  } = useWorkerExclusions(workplaceId, [workerId]);

  const queryClient = useQueryClient();
  const createWorkerReviewMutation = useMutation({
    mutationFn: createWorkerReview,
    onSuccess: () => {
      resetPreferredWorkerQueries(queryClient);
    },
  });
  const createExclusionMutation = useMutation({
    mutationFn: createExclusion,
  });
  const deleteExclusionMutation = useMutation({
    mutationFn: deleteExclusion,
  });

  const isInitialLoading = workersForRatingLoading || exclusionsLoading;
  const isLoading =
    isInitialLoading ||
    createExclusionMutation.isLoading ||
    createWorkerReviewMutation.isLoading ||
    deleteExclusionMutation.isLoading;

  const handleSubmit = () => {
    if (mode === "view") {
      setMode("edit");
      form.setFieldsValue(formState?.rating);
    } else {
      createRatingPerformance();
    }
  };

  const createRatingPerformance = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      // if there is any form validation errors, bail out
      return;
    }
    const workerForRating = formState;
    if (!workerForRating?.rating) {
      return;
    }

    const exclusion = exclusionsData[workerForRating.worker.userId];
    const dnrAction = getDNRAction(workerForRating.rating?.dnrWorker, exclusion);

    let workerReview: WorkerReviewDetails;

    try {
      workerReview = await createWorkerReviewMutation.mutateAsync({
        rating: workerForRating.rating.rating,
        dnrWorker: workerForRating.rating.dnrWorker,
        additionalFeedback: workerForRating.rating.additionalFeedback,
        qualities: workerForRating.rating.qualities,
        workerId: workerForRating.worker.userId,
        workplaceUserId,
        shiftId: workerForRating.rating.shiftId,
        workplaceId,
        id: workerForRating.rating.id,
      });

      if (isFacilityRulesAiEnabled && workerReview.rating < 5) {
        attemptRateLimitedAction({
          onRateLimitExceeded: () => {
            showSuccessToast("Rating submitted");
          },
          onRateLimitNotExceeded: () => {
            showSuccessToast(
              "Rating submitted. Please click here to update your facility's notes with any unmet expectations to ensure professionals meet your standards.",
              {
                onClick: () => {
                  logEvent(HCF_USER_EVENTS.CLICKED_UPDATE_FACILITY_NOTES_ALERT, {
                    workplaceId,
                    source: "RATING",
                  });
                  history.push(routes.facilityWorkerInstructions);
                },
              }
            );
            logEvent(HCF_USER_EVENTS.VIEWED_UPDATE_FACILITY_NOTES_ALERT, {
              workplaceId,
              source: "RATING",
            });
          },
        });
      } else {
        showSuccessToast("Rating submitted");
      }
    } catch (error) {
      handleClosed();
      logWorkerReviewSubmitted(dnrAction, exclusion, {
        success: false,
        method: "submitFailed",
        id: formState?.rating?.id,
      });
      return handleWorkerRatingErrorMessageDisplay(error);
    }

    try {
      let createdExclusion: ExclusionForView | undefined;
      const showRemoveDnr =
        (dnrAction === DnrAction.DELETE ||
          doesRatingMakeWorkerFavorite(workerForRating.rating.rating)) &&
        exclusion;
      if (dnrAction === DnrAction.CREATE) {
        createdExclusion = await createExclusionMutation.mutateAsync({
          actionBy,
          reason: WORKER_REVIEW_DNR_REASON,
          notes: workerForRating.rating.additionalFeedback ?? "",
          facilityId: workplaceId,
          agentId: workerForRating.worker.userId,
          shiftReviewId: workerReview.id,
          adminId: workplaceUserId,
        });
      } else if (showRemoveDnr) {
        // Delete the existing DNR, if DNR option is not selected and their is already a DNR database document created.
        await deleteExclusionMutation.mutateAsync(exclusion?._id);
      }

      logWorkerReviewSubmitted(dnrAction, showRemoveDnr ? undefined : exclusion, {
        success: true,
        id: workerReview.id,
      });
      handleClosed();
      successCallback?.(workerReview, createdExclusion);
    } catch (err) {
      showErrorToast("There is an error when creating rating performance.");
      logWorkerReviewSubmitted(dnrAction, exclusion, {
        success: false,
        method: "submitFailed",
        id: formState?.rating?.id,
      });
    }
  };

  const logWorkerReviewSubmitted = (
    dnrAction: DnrAction | undefined,
    exclusion: ExclusionForList | undefined,
    options: Record<string, string | boolean | undefined>
  ) => {
    if (!formState?.rating) {
      return;
    }

    captureWorkerReviewSubmittedEvent(
      convertToWorkerReviewState(
        formState.rating,
        exclusion,
        mode === "view"
          ? WorkerReviewMode.VIEW
          : formState.rating.id
          ? WorkerReviewMode.EDIT
          : WorkerReviewMode.SUBMIT,
        dnrAction
      ),
      initialFormState?.rating
        ? convertToWorkerReviewState(
            initialFormState.rating,
            exclusion,
            WorkerReviewMode.VIEW,
            undefined
          )
        : {},
      {
        workerId,
        workplaceId,
        workplaceUserId,
        dnrAction,
        ...trackData,
        ...options,
      }
    );
  };

  const handleClosed = () => {
    form.resetFields();
    invalidateCacheWorkerExclusions();
    invalidateCacheWorkersForRating();
    onClose();
  };

  const handleFormStateChanged = (rating: Partial<Rating>) => {
    setFormState((prevState) => {
      if (!prevState) {
        throw new Error("rating performance is in invalid state.");
      }

      return {
        ...prevState,
        rating: { ...prevState.rating, ...rating },
      };
    });
    form.setFieldsValue(rating);
  };

  return (
    <StyledModal visible={visible} footer={false} onCancel={handleClosed} width="540px">
      {!isInitialLoading && formState ? (
        <Wrapper>
          {mode === "view" && formState.rating ? (
            <ViewRatingPerformance
              rating={formState.rating}
              worker={formState.worker}
              lastShift={formState.lastShift}
              timezone={timezone}
            />
          ) : (
            <RatingPerformanceForm
              key={formState.worker.userId}
              worker={formState.worker}
              lastShift={formState.lastShift}
              rating={formState.rating}
              timezone={timezone}
              exclusion={exclusionsData[formState.worker.userId]}
              onRatingChanged={handleFormStateChanged}
              form={form}
            />
          )}
          <Footer>
            <Button
              variant="primary"
              data-testid="worker-rating-performance-submit"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {getSubmitText(
                mode,
                formState.rating?.id,
                doesRatingMakeWorkerFavorite(formState.rating?.rating) &&
                  !!exclusionsData[formState.worker.userId]
              )}
            </Button>
          </Footer>
        </Wrapper>
      ) : (
        <Skeleton active paragraph />
      )}
    </StyledModal>
  );
}
