import { ArrowLeftOutlined } from "@ant-design/icons";

import "@sendbird/uikit-react/dist/index.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "../chatStyle.scss";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { logProfileNavigated } from "src/containers/workers/logs";
import { WhoCalledRatingPerformanceModal } from "src/components/WorkerRatingPerformanceModal/workerReview.types";
import { getLocation } from "src/utils/routes";
import { getChannelTitle } from "../channel";
import { ShiftInviteDialog } from "src/containers/Shift/ShiftInvites/Dialogs/index";
import { useModalState } from "@clipboard-health/ui-react";
import { useWorker } from "@src/appV2/Worker/api/useWorker";
import { useShiftInfo } from "../utils/useShiftInfo";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, Button, Tooltip } from "@mui/material";
import { StreakIndicator } from "@src/appV2/Worker/StreakIndicator/StreakIndicator";
import { useIsStreaksPreRewardsEnabled } from "@src/appV2/Worker/api/useIsStreaksPreRewardsEnabled";
import { StreakStatus, useGetFacilityStreaks } from "@src/appV2/Worker/api/useGetFacilityStreaks";
import { MONGO_DB_ID_LENGTH } from "@src/constants/database";
import { isFavoritedReason } from "../../workers/ListWorkers/utils";
import { usePreferredWorker } from "@src/containers/workplace/WorkerDetails/usePreferredWorker";
import { FavoriteIndicator } from "@src/appV2/Worker/FavoriteIndicator/FavoriteIndicator";
import { useWorkplaceWorker } from "@src/containers/workers/WorkerDetails/useWorkplaceWorker";
import {
  ShiftCategory,
  useGetWorkerDetailsAtWorkplace,
  DEFAULT_PAGE_SIZE,
} from "@src/appV2/Professionals/api/useGetWorkerDetailsAtWorkplace";
import { isEmpty } from "lodash";
import { FacilityStatusObj } from "@src/utils/facility";
import { PENDING_ACCOUNT_TOOLTIP_MESSAGE } from "@src/appV2/Calendar/constants";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ChatChannel } from "../chat.types";

export interface ChatHeaderProps {
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
  currentChannel?: ChatChannel;
  backAction: boolean;
  facilityStatus: typeof FacilityStatusObj[keyof typeof FacilityStatusObj];
}

export function ChatHeader(props: ChatHeaderProps) {
  const { workerId, workplaceId, workplaceUserId, currentChannel, backAction, facilityStatus } =
    props;
  const editShiftInviteModalState = useModalState();
  const { data: worker, isSuccess: isWorkerSuccess } = useWorker(workerId);
  const shiftInfo = useShiftInfo(workplaceId);
  const history = useHistory();

  const handleWorkerNameClicked = (withNavigation: boolean) => {
    if (withNavigation) {
      history.push(getLocation("workplaceWorkerDetails", { pathParams: { workerId } }));
    }
    logProfileNavigated({
      workerId,
      workplaceId,
      workplaceUserId,
      location: WhoCalledRatingPerformanceModal.CHAT_CHANNEL,
    });
  };

  const isStreaksPreRewardsEnabled = useIsStreaksPreRewardsEnabled();

  const streaksQueryResults = useGetFacilityStreaks(
    {
      facilityId: workplaceId,
      filter: { agentIds: [workerId] },
    },
    {
      enabled:
        isStreaksPreRewardsEnabled &&
        isDefined(workplaceId) &&
        workplaceId.length === MONGO_DB_ID_LENGTH &&
        workerId.length === MONGO_DB_ID_LENGTH,
    }
  );

  const currentStreak = streaksQueryResults.isSuccess
    ? streaksQueryResults.data.data.find(
        ({ attributes }) => attributes.status === StreakStatus.ACTIVE
      )
    : undefined;

  const isFavoriteFlagEnabled = useCbhFlag(CbhFeatureFlag.PRIORITY_ACCESS, {
    defaultValue: {
      "*": false,
    },
  })?.["*"];

  const { data: preferredWorker } = usePreferredWorker(workplaceId, workerId);

  const { exclusion } = useWorkplaceWorker({
    workerId,
    workplaceId,
    workplaceUserId,
  });

  const workerIsFavorite = isFavoritedReason(preferredWorker?.reason);

  const workerDetails = useGetWorkerDetailsAtWorkplace(
    {
      category: workerIsFavorite ? ShiftCategory.FAVORITE : ShiftCategory.PAST,
      search: worker?.name,
      facilityId: workplaceId,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    {
      enabled:
        isStreaksPreRewardsEnabled &&
        isWorkerSuccess &&
        isDefined(worker?.name) &&
        !isEmpty(worker?.name) &&
        isDefined(workplaceId),
    }
  );

  // TODO: filtering will be refactored out after https://linear.app/clipboardhealth/issue/CS-3027/add-an-optional-list-of-workerids-to-the-query-params-for-the-endpoint is complete
  const currentWorkerDetails = workerDetails.isSuccess
    ? workerDetails.data.workers.find((worker) => worker.userId === workerId)
    : undefined;

  const { cancelledInLastMonth = 0 } = currentWorkerDetails?.shifts ?? {};

  return (
    <div className="custom-header">
      <div className="custom-header-title">
        {backAction && (
          <ArrowLeftOutlined
            className="close-channel"
            onClick={() => history.push(getLocation("facilityChat"))}
          />
        )}
        <Stack direction="column" spacing={0.5}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              to={getLocation("workplaceWorkerDetails", {
                pathParams: { workerId },
              })}
              onClick={() => handleWorkerNameClicked(false)}
              className="custom-header-worker"
            >
              {getChannelTitle(currentChannel)}
            </Link>
            {isStreaksPreRewardsEnabled && (
              <div>
                {currentStreak && <StreakIndicator streak={currentStreak.attributes} />}
                {isFavoriteFlagEnabled && workerIsFavorite && !isDefined(exclusion) && (
                  <FavoriteIndicator
                    cancelled={isDefined(cancelledInLastMonth) && cancelledInLastMonth > 0}
                  />
                )}
              </div>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Tooltip
              title={PENDING_ACCOUNT_TOOLTIP_MESSAGE}
              disableHoverListener={facilityStatus !== FacilityStatusObj.PENDING}
              arrow
            >
              <span>
                <Button
                  onClick={() => {
                    editShiftInviteModalState.openModal();
                    logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
                      action: "CHAT_MODAL_OPEN",
                      workplaceId,
                      workplaceUserId,
                      workerType: worker?.qualification,
                      ...shiftInfo,
                    });
                  }}
                  variant="contained"
                  disabled={facilityStatus === FacilityStatusObj.PENDING}
                >
                  Invite Professional
                </Button>
              </span>
            </Tooltip>
            <Button onClick={() => handleWorkerNameClicked(true)} variant="outlined">
              View profile
            </Button>
          </Stack>
        </Stack>
      </div>
      {isWorkerSuccess && isDefined(shiftInfo) && (
        <ShiftInviteDialog
          workerType={worker.qualification}
          modalState={editShiftInviteModalState}
          shiftInfo={shiftInfo}
          initialActiveState={1}
          initialSelectedWorker={{
            workerId: worker.userId,
            phoneNumber: worker.phone,
            fullName: worker.name,
          }}
        />
      )}
    </div>
  );
}
