import React from "react";
import { TextField } from "@mui/material";

interface ChatSearchBarProps {
  searchQuery: string;
  setSearchQuery: (x: string) => void;
}

export function ChatSearchBar({ searchQuery, setSearchQuery }: ChatSearchBarProps) {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <TextField
      fullWidth
      sx={{
        padding: 1,
      }}
      placeholder="Search professionals..."
      value={searchQuery}
      onChange={handleSearch}
    />
  );
}
