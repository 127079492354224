import { useMutation } from "@tanstack/react-query";
import { getAuthHeader } from "src/utils/superagent";
import request from "superagent";
import { z } from "zod";
import { useSendbirdStateContext } from "@sendbird/uikit-react";
import { fetchChannels } from "./channel";
import { useDispatch, useSelector } from "react-redux";
import { isDefined } from "@clipboard-health/util-ts";
import { SessionType, SendbirdChat } from "src/modules/interface";

const CreateChatChannelSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
});

export type CreateChatChannelArguments = z.infer<typeof CreateChatChannelSchema>;

export function useCreateChatChannel() {
  const dispatch = useDispatch();
  const { channels } = useSelector<SessionType, SendbirdChat>((state) => state.chat);
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  return useMutation({
    mutationFn: async (variables: CreateChatChannelArguments) => {
      if (
        channels.some((channel) => channel.url === `${variables.facilityId}_${variables.agentId}`)
      ) {
        return;
      }
      const response = await request
        .post(`${global.api}/chat/channels`)
        .set(await getAuthHeader())
        .send(CreateChatChannelSchema.parse(variables));

      if (!response.ok) {
        throw new Error(`${response.statusCode}: Could not create chat channel.`);
      } else if (isDefined(sdk)) {
        try {
          await fetchChannels({ sdk, dispatch });
        } catch (e) {
          throw new Error(`Could not fetch chat channels: ${e}`);
        }
      }
    },
  });
}
