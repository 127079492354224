import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Avatar, Dropdown, Menu, Modal, notification } from "antd";
import { FacilityOnboardingStep } from "src/api/facility";
import {
  PreferredWorker,
  WorkerDirectory,
  addPreferredWorkers,
  deletePreferredWorkers,
  resetPreferredWorkerQueries,
} from "src/api/workers";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { LinkButtonWithIcon } from "src/containers/facilityOnboarding/FacilityOnboarding.styles";
import { ListWorkerReviews } from "src/containers/workplace/WorkerDetails/components/ListWorkerReviews";
import { ReviewListTitle } from "src/containers/workplace/WorkerDetails/components/ListWorkerReviews.styles";
import { Button } from "src/designsystem/Button/Button";
import { InfoPopover } from "src/designsystem/Popover/Popover";
import { VerticalSpacing } from "src/designsystem/VerticalSpacing/VerticalSpacing";
import { SessionType } from "src/modules/interface";
import { UPDATE_PROFILE } from "src/modules/session";
import { logEvent } from "@src/appV2/lib/analytics";
import { MouseEventHandler, useState } from "react";
import { FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";
import {
  ButtonIcon,
  List,
  QualificationText,
  Worker,
  WorkerActions,
  WorkerAvatar,
  WorkerName,
  WorkerRate,
  WorkerRatingSection,
} from "./WorkerDetails.styles";
import { AdminActionDisabledTooltip } from "src/components/AdminActionDisabledTooltip/AdminActionDisabledTooltip";
import { getLocation } from "src/utils/routes";
import { useRestrictedSignedProfile } from "src/hooks/useRestrictedSignedProfile";
import { RestrictedSignedProfile } from "src/components/RestrictedSignedProfile/RestrictedSignedProfile";
import { MIN_HIGH_RATING_VALUE } from "src/containers/workers/constants";
import { createWorkerReview } from "src/api/workerReview";
import { useCreateChatChannel } from "src/containers/chat/useCreateChatChannel";
import { ChatModal } from "src/containers/chat/chat";
import { getErrorMessage } from "src/utils/errors";
import { BsChatText } from "react-icons/bs";
import { Grid } from "@mui/material";
import { isDefined } from "@clipboard-health/util-ts";
import { WhoCalledChatModal, logChatOpen } from "src/containers/chat/utils/logEvents";
import { showErrorToast } from "@src/appV2/lib/Notifications";
import { ExternalLink } from "@clipboard-health/ui-react";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const WORKER_DIRECTORY_ITEM = {
  FAVORITED: "favorited",
  UNFAVORITED: "unfavorited",
  MORE_OPTIONS: "more options",
  PLAY_VIDEO: "play video",
  ITEM_CLICK: "worker tile click",
  INFO_POPOVER_CLOSED: "info popover closed",
  VIEW_REVIEWS_OPENED: "view reviews opened",
  VIEW_REVIEWS_CLOSED: "view reviews closed",
  VIEW_REVIEWS_READ_MORE: "view reviews read more",
  CLICK_PRIORITY_ACCESS_LEARN_MORE: "click priority access learn more",
} as const;

type WorkerDirectoryActionKeys = keyof typeof WORKER_DIRECTORY_ITEM;
type WorkerDirectoryActionValues = typeof WORKER_DIRECTORY_ITEM[WorkerDirectoryActionKeys];

interface WorkerDetailsProps {
  position: number;
  total: number;
  worker: WorkerDirectory;
  workplaceId: string;
  isFavorited: boolean;
  onFavoritedChanged: (reason?: PreferredWorker["reason"]) => void;
  workplaceUserId?: string;
  workplaceOnboardingSteps: Array<"favorites" | "shifts">;
  postShiftOptionSteps: Array<"block-shifts" | "per-diem">;
  onboardingState?: FacilityOnboardingStep;
  whoCalledMe: "FacilityOnboardingPage" | "DirectoryWorkerPage";
  hasPermissionToAccessBlockBookingFeature: boolean;
  favoritedReason?: PreferredWorker["reason"];
}

const PAGE_TO_NAME = {
  FacilityOnboardingPage: "Get Started",
  DirectoryWorkerPage: "Worker Directory",
} as const;

const INFO_POPOVER_WORKER_DIRECTORY_ITEM = "infoPopoverWorkerDirectoryItem";

export function WorkerDetails({
  position,
  total,
  worker,
  workplaceId,
  isFavorited,
  onFavoritedChanged,
  workplaceUserId,
  workplaceOnboardingSteps,
  postShiftOptionSteps,
  onboardingState,
  whoCalledMe,
  hasPermissionToAccessBlockBookingFeature,
  favoritedReason,
}: WorkerDetailsProps): JSX.Element {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showInfoPopover, setShowInfoPopover] = useState(true);
  const [infoPopoverWorkerDirectoryItem, setInfoPopoverWorkerDirectoryItem] = useLocalStorage(
    `${INFO_POPOVER_WORKER_DIRECTORY_ITEM}_${workplaceId}_${workplaceUserId}`,
    false
  );
  const isChatCreationEnabled = useCbhFlag(CbhFeatureFlag.ROLLOUT_CHAT_CREATION, {
    defaultValue: false,
  });
  const dispatch = useDispatch();
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [chatModalUrl, setChatModalUrl] = useState<string>();
  const { isSignedProfile, openContractModal } = useRestrictedSignedProfile();
  const history = useHistory();
  const { profile, admin, user } = useSelector((state: SessionType) => state.session);

  const queryClient = useQueryClient();
  const { mutateAsync: deletePreferredWorkersApi, isLoading: deletePreferredWorkersLoading } =
    useMutation({
      mutationFn: deletePreferredWorkers,
      onSuccess: () => {
        resetPreferredWorkerQueries(queryClient);
      },
    });
  const { mutateAsync: addPreferredWorkersApi, isLoading: addPreferredWorkersLoading } =
    useMutation({
      mutationFn: addPreferredWorkers,
      onSuccess: () => {
        resetPreferredWorkerQueries(queryClient);
      },
    });
  const { mutateAsync: updateWorkerReviewApi, isLoading: updateWorkerReviewLoading } = useMutation({
    mutationFn: createWorkerReview,
  });

  const { mutateAsync: createChatChannel, isLoading: createChatChannelLoading } =
    useCreateChatChannel();

  const logWorkerDirectoryItemEvents = (
    action: WorkerDirectoryActionValues,
    subAction?: string,
    reviewId?: string
  ) => {
    if (admin) {
      return;
    }

    const commonAttributes = {
      workplaceOnboardingSteps,
      postShiftOptionSteps,
      workplaceId,
      workplaceUserId,
      onboardingState,
      workerId: worker.workerId,
      itemPosition: `${position}/${total}`,
      videoUrl: worker?.profileVideoUrl,
      previousLocation: whoCalledMe,
    };

    logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY_ITEM, {
      ...commonAttributes,
      action,
      subAction,
      reviewId,
    });
  };

  const navigateToFavoritedPage: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    notification.destroy();
    history.push(
      getLocation("workplaceWorkers", {
        queryParams: { category: "favorite" },
      })
    );
  };

  const navigateToWorkerBlockShifts = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.preventDefault();
    notification.destroy();
    history.push(getLocation("workplaceBlockShifts"), {
      workerId: worker.workerId,
      previousLocation: "DirectoryWorkerItem",
    });
  };

  const toggleFavorited: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (isFavorited) {
      removeFavoritedWorker();
    } else {
      addFavoritedWorker();
    }
  };

  const addFavoritedWorker = async () => {
    await addPreferredWorkersApi({
      workerId: worker.workerId,
      workplaceId,
      reason: "FAVORITE",
    });
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.FAVORITED);
    onFavoritedChanged("FAVORITE");
    if (profile.onboardingSteps?.favoriteWorkers === false) {
      dispatch({
        type: UPDATE_PROFILE,
        data: {
          ...profile,
          onboardingSteps: {
            ...profile.onboardingSteps,
            favoriteWorkers: true,
          },
        },
      });
    }

    notification.success({
      key: "added-to-favorites-team",
      message: (
        <span>
          Added to your Favorites Team.
          <br />
          This professional now gets early access to your shifts.
        </span>
      ),

      btn: (
        <Button variant="primary" onClick={navigateToFavoritedPage}>
          View all Favorites
        </Button>
      ),
      getContainer: () => document.querySelector("#content-box") as HTMLDivElement,
    });
  };

  const removeFavoritedWorker = async () => {
    if (favoritedReason === "FAVORITE" || favoritedReason === "INTERNAL_CRITERIA") {
      await deletePreferredWorkersApi({ workerId: worker.workerId, workplaceId });
      logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.UNFAVORITED);
      notification.success({
        key: "removed-favorites-team",
        message: <>The professional has been removed from your Favorites list.</>,
      });
    } else {
      const updatedReview = await updateWorkerReviewApi({
        workerId: worker.workerId,
        workplaceId,
        rating: MIN_HIGH_RATING_VALUE - 1,
        workplaceUserId: user?._id ?? "",
        qualities: ["Unprofessional"],
      });
      logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.UNFAVORITED, "RATING", updatedReview.id);
      notification.success({
        key: "removed-favorites-team",
        message: (
          <>
            The professional has been removed from your Favorites list and their rating has been
            updated to 3 stars.
          </>
        ),
      });
    }

    onFavoritedChanged();
  };

  const handleWorkerClicked = () => {
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.ITEM_CLICK);
    history.push(
      getLocation("workersDirectoryWorkerDetails", {
        pathParams: { workerId: worker.workerId },
      }),
      { previousLocation: whoCalledMe }
    );
  };

  const handleMenuItemClick = ({
    key,
    domEvent,
  }: {
    key: string;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  }) => {
    domEvent.stopPropagation();
    if (key === "block-shifts") {
      if (isSignedProfile) {
        navigateToWorkerBlockShifts(domEvent);
        logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.MORE_OPTIONS, key);
      } else {
        openContractModal({
          triggeredBy: "offer a block of shifts",
          triggeredFrom: PAGE_TO_NAME[whoCalledMe],
        });
      }
    } else {
      history.push(
        getLocation("workersDirectoryWorkerDetails", {
          pathParams: {
            workerId: worker.workerId,
          },
        }),
        {
          previousLocation: whoCalledMe,
        }
      );
      logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.MORE_OPTIONS, key);
    }
  };

  const handleViewWorkerVideo: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.PLAY_VIDEO);
    setShowVideoModal(true);
  };

  const handleCloseInfoPopover = () => {
    setShowInfoPopover(false);
    setInfoPopoverWorkerDirectoryItem(true);
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.INFO_POPOVER_CLOSED);
  };

  const handleViewRatingsClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    // since the rating component is inside the clickable worker item, we need to stop the event from propagating to the worker item
    event.stopPropagation();
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_OPENED);
    setShowReviewsModal(true);
  };

  const handleViewRatingsClosed = () => {
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_CLOSED);
    setShowReviewsModal(false);
  };

  const handleReadMore = (reviewId: string) => {
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_READ_MORE, undefined, reviewId);
  };

  const renderRatingsModalHeader = (averageRating, totalReviews) => {
    return (
      <ReviewListTitle>
        <FaStar /> <span>{averageRating.toFixed(1)} Average</span>
        <span>({totalReviews !== 1 ? `${totalReviews ?? 0} Reviews` : "1 Review"})</span>
      </ReviewListTitle>
    );
  };

  return (
    <>
      <Worker onClick={handleWorkerClicked}>
        <WorkerAvatar blur={!isSignedProfile}>
          <Avatar size={96} icon={<UserOutlined />} src={worker.profileImageUrl} />
          {worker.profileVideoUrl && (
            <RestrictedSignedProfile action="play video" page="Worker Directory">
              <LinkButtonWithIcon underline={false} color="primary" onClick={handleViewWorkerVideo}>
                <MdOutlineSlowMotionVideo />
                Play video
              </LinkButtonWithIcon>
            </RestrictedSignedProfile>
          )}
        </WorkerAvatar>
        <Grid container alignItems="flex-start" wrap="nowrap">
          <Grid item xs={12}>
            <Grid container wrap="nowrap" spacing={1} justifyContent="space-between">
              <Grid item xs={7}>
                <WorkerName>{isSignedProfile ? worker.name : "Anonymous"}</WorkerName>
                <QualificationText>
                  {worker.matchingFacilityQualifications.join(", ")}
                </QualificationText>
              </Grid>
              <Grid item>
                <WorkerActions>
                  {isChatCreationEnabled && (
                    <AdminActionDisabledTooltip disabled={admin}>
                      <RestrictedSignedProfile
                        action="worker chat"
                        page={PAGE_TO_NAME[whoCalledMe]}
                      >
                        <ButtonIcon
                          variant="secondary"
                          onClick={async (event) => {
                            event.stopPropagation();
                            try {
                              await createChatChannel({
                                facilityId: workplaceId,
                                agentId: worker.workerId,
                              });
                              logChatOpen({
                                workerId: worker.workerId,
                                workplaceId,
                                workplaceUserId: workplaceUserId ?? "",
                                location: WhoCalledChatModal.BROWSE_PROFESSIONALS_PAGE,
                              });
                              setChatModalUrl(`${workplaceId}_${worker.workerId}`);
                            } catch (error) {
                              showErrorToast(getErrorMessage(error));
                            }
                          }}
                          disabled={admin || createChatChannelLoading}
                        >
                          <BsChatText />
                        </ButtonIcon>
                      </RestrictedSignedProfile>
                    </AdminActionDisabledTooltip>
                  )}
                  <InfoPopover
                    variant="primary"
                    content={
                      <p>
                        Mark your preferred professionals with a heart and they'll get first dibs on
                        your shifts.{" "}
                        <ExternalLink
                          to="https://support.clipboardhealth.com/hc/en-us/articles/10149672193815-Understanding-Priority-Access"
                          onClick={() =>
                            logWorkerDirectoryItemEvents(
                              WORKER_DIRECTORY_ITEM.CLICK_PRIORITY_ACCESS_LEARN_MORE
                            )
                          }
                          sx={{
                            color: "white",
                            textDecorationColor: "white",
                            "&:hover": {
                              color: "white",
                              textDecorationColor: "white",
                            },
                          }}
                        >
                          Learn more.
                        </ExternalLink>
                      </p>
                    }
                    open={showInfoPopover}
                    placement="right"
                    showPopover={
                      history.location.pathname === "/workplace/workers-directory" &&
                      position === 1 &&
                      infoPopoverWorkerDirectoryItem === false &&
                      profile.onboardingSteps?.favoriteWorkers === false &&
                      !admin
                    }
                    onHandleClose={handleCloseInfoPopover}
                  >
                    <AdminActionDisabledTooltip disabled={admin}>
                      <RestrictedSignedProfile
                        action={isFavorited ? "unfavorite a worker" : "favorite a worker"}
                        page={PAGE_TO_NAME[whoCalledMe]}
                      >
                        <ButtonIcon
                          variant={isFavorited ? "primary" : "secondary"}
                          onClick={toggleFavorited}
                          disabled={
                            admin ||
                            deletePreferredWorkersLoading ||
                            addPreferredWorkersLoading ||
                            updateWorkerReviewLoading
                          }
                        >
                          {isFavorited ? <FaHeart /> : <FaRegHeart />}
                        </ButtonIcon>
                      </RestrictedSignedProfile>
                    </AdminActionDisabledTooltip>
                  </InfoPopover>
                  <Dropdown
                    trigger={["click"]}
                    onVisibleChange={(visible) => {
                      if (visible) {
                        logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.MORE_OPTIONS);
                      }
                    }}
                    overlay={
                      <Menu onClick={handleMenuItemClick}>
                        {!admin && hasPermissionToAccessBlockBookingFeature && (
                          <Menu.Item key="block-shifts">Offer block booking</Menu.Item>
                        )}
                        <Menu.Item key="view-profile">View profile</Menu.Item>
                      </Menu>
                    }
                  >
                    <ButtonIcon
                      variant="secondary"
                      onClick={(event) => event.stopPropagation()}
                      aria-label="Open workers directory more options"
                    >
                      <EllipsisOutlined />
                    </ButtonIcon>
                  </Dropdown>
                </WorkerActions>
              </Grid>
            </Grid>
            <RestrictedSignedProfile action="view worker's reviews" page="Worker Directory">
              <WorkerRatingSection onClick={handleViewRatingsClick}>
                {worker.ratingsCount !== 0 && (
                  <WorkerRate allowHalf value={worker.averageRating} disabled />
                )}
                {worker.ratingsCount !== 1
                  ? `${worker.ratingsCount ? `${worker.ratingsCount} reviews` : "No reviews yet"}`
                  : "1 review"}
              </WorkerRatingSection>
            </RestrictedSignedProfile>
            <VerticalSpacing size="sm" />
            <List>
              <li>
                {worker.completedShiftsCount !== 1
                  ? `${worker.completedShiftsCount ?? 0} shifts `
                  : "1 shift "}
                completed
              </li>
              <li>
                {worker.attendanceRate > 0 ? `${worker.attendanceRate}% ` : "N/A "}
                shift attendance rate
              </li>
            </List>
          </Grid>
        </Grid>
      </Worker>
      {showVideoModal && (
        <Modal
          visible={showVideoModal}
          width={780}
          onCancel={() => setShowVideoModal(false)}
          footer={null}
          modalRender={() => (
            <iframe
              width="98%"
              height="420px"
              src={worker.profileVideoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              style={{ pointerEvents: "auto" }}
            />
          )}
        />
      )}

      {showReviewsModal && (
        <Modal
          visible={showReviewsModal}
          width={680}
          onCancel={handleViewRatingsClosed}
          footer={null}
          title={renderRatingsModalHeader(worker.averageRating, worker.ratingsCount)}
        >
          <ListWorkerReviews workerId={worker.workerId} handleReadMore={handleReadMore} />
        </Modal>
      )}

      {isDefined(chatModalUrl) && (
        <ChatModal channelUrl={chatModalUrl} closeModal={() => setChatModalUrl(undefined)} />
      )}
    </>
  );
}
