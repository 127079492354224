import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAuthHeader } from "../../../utils/superagent";
import request from "superagent";
import { SHIFT_INVITES_URL } from "@src/appV2/ShiftInvites/constants";

export interface ShiftDetails {
  start: Date;
  end: Date;
  agentReq: string;
  name: string;
  tmz: string;
  customChargeRateBoost?: number;
}

export interface ShiftInvite {
  workerId: string;
  workplaceId: string;
  shiftDetails: ShiftDetails;
  postShiftToMarketplace: boolean;
  groupId?: string;
}

export interface ShiftInviteRequest {
  data: {
    attributes: ShiftInvite;
    type: "shiftInvite";
  };
}

export interface ShiftInviteQueryParams {
  isOverworkingShiftsAllowed?: boolean;
  skipFacilityRequiredDocumentCheck?: boolean;
}

export interface ShiftInviteRequestParams {
  shiftInviteRequest: ShiftInvite;
  queryParams?: ShiftInviteQueryParams;
}

export function useCreateShiftInvite() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invite: ShiftInviteRequestParams) => {
      const { shiftInviteRequest, queryParams = {} } = invite;
      const { isOverworkingShiftsAllowed = false, skipFacilityRequiredDocumentCheck = false } =
        queryParams;
      const authHeader = await getAuthHeader();
      const response = await request
        .post(SHIFT_INVITES_URL)
        .set(authHeader)
        .query({
          ...(isOverworkingShiftsAllowed ? { isOverworkingShiftsAllowed } : {}),
          ...(skipFacilityRequiredDocumentCheck ? { skipFacilityRequiredDocumentCheck } : {}),
        })
        .send({
          data: {
            type: "shiftInvite",
            attributes: shiftInviteRequest,
          },
        });
      queryClient.invalidateQueries({ queryKey: [SHIFT_INVITES_URL] });
      return response;
    },
  });
}
