/* eslint-disable prettier/prettier */
import { LocationDescriptorObject } from "history";
import * as pathToRegexp from "path-to-regexp";
import queryString from "query-string";

type Location = LocationDescriptorObject<Record<string, unknown>>;

/**
 * Only use for Route component
 */
export const routes = {
  // generic
  root: "/",
  enter: "/enter",
  loginWithEmailPassword: "/login-with-email-password",
  admin: "/admin",
  myAccount: "/my-account",
  loginVerify: "/login/verify",
  appV2: "/v2",

  // facility
  facilityDashboard: "/facility/dashboard",
  facilityOnboarding: "/facility/onboarding",
  facilityLiveWorkerETA: "/facility/live-worker-eta",
  facilityUsers: "/facility/users",
  facilityWorkerInstructions: "/facility/worker-instructions",
  facilityTimeclockSettings: "/facility/custom-settings",
  facilityChat: "/facility/chat",
  facilityChatWithAgent: "/facility/chat/:facilityId?/:agentId?",
  facilityInvoiceHistory: "/facility/invoice/history",
  facilityInvoiceDispute: "/facility/invoice/dispute/:invoiceId",
  facilityInvoiceDisputeRedirect: "/facility/invoice/dispute/:invoiceNumber/redirect",
  facilityInvoiceUpcomingCharges: "/facility/invoice/upcoming-charges",
  facilityInvoiceContractTerms: "/facility/invoice/contract-terms",
  facilityProfile: "/facility/profile",
  facilitySettings: "/facility/settings",

  // workplace
  workplaceGetAccess: "/workplace/get-access",
  workplaceReferrals: "/workplace/get-access/referrals",
  workplaceSignContract: "/workplace/:workplaceId/sign-contract",
  workplaceWorkers: "/workplace/workers",
  workplaceWorkerDetails: "/workplace/workers/:workerId",
  workersDirectory: "/workplace/workers-directory",
  workersDirectoryWorkerDetails: "/workplace/workers-directory/:workerId",
  workplaceUsers: "/workplace/users",
  workplaceUserDetails: "/workplace/users/:userId",
  workplaceBlockShifts: "/workplace/block-shifts",
  workplaceReferAndEarn: "/workplace/refer-and-earn",
  workplaceHireProfessionals: "/workplace/hire-professionals",
  workplaceHireProfessionalDetails: "/workplace/hire-professionals/:placementCandidateId",

  // workers
  workers: "/workers",
  document: "/documents/:userId",
  agentDashboard: "/agent/dashboard",
  agentPayroll: "/agent/payroll",
  agentProof: "/agent/proof",
  agentStripePayouts: "/agent/stripe-payouts",
  agentStripeTransfers: "/agent/stripe-transfers",
  agentAttendance: "/agent/attendance",
  agentProfile: "/agent/profile",
  agentICAgreement: "/agent/:workerId/ic-agreement/:icAgreementVersion/download",
  agentPlacement: "/agent/placement",

  calendar: "/calendar",
  reference: "/reference/:id",
  timesheetRedirect: "/timesheet/:invoiceNumber/redirect",
  timesheet: "/timesheet/:id",
  timesheetPdf: "/timesheet/pdf/:id",
  signatorySign: "/signatorySign/:facilityId/:signatoryId",

  verifyDocument: "/verify-document",

  // admin
  adminDashboard: "/admin/dashboard",
  adminTimeclock: "/admin/timeclockrequests",
  adminProfile: "/admin/profile",
  adminInvoice: "/admin/invoice/:id",
  adminInvoicePdf: "/admin/invoice/pdf/:id",
  adminInvoiceDispute: "/admin/post-invoice-dispute/:invoiceId",
  adminPreInvoiceDispute: "/admin/pre-invoice-dispute/:invoiceId/:invoiceItemId",
  adminAccounts: "/admin/accounts",
  adminAccountsAgents: "/admin/accounts/agents",
  adminAccountsEmployees: "/admin/accounts/employees",
  adminAccountsInvocingByPeriod: "/admin/accounts/invoicing/byperiod",
  adminAccountsInvocingByNumber: "/admin/accounts/invoicing/bynumber",
  adminAccountsPostInvoiceDisputes: "/admin/accounts/invoicing/post-invoice-disputes",
  adminAccountsPreInvoiceDisputes: "/admin/accounts/invoicing/pre-invoice-disputes",
  adminAccountsFacilities: "/admin/accounts/facilities",
  adminAccountsRequirement: "/admin/accounts/requirements",
  adminAccountsLocations: "/admin/accounts/locations",
  adminAccountsChangeLog: "/admin/accounts/changelog",
  adminAccountsExclusions: "/admin/accounts/exclusions",
  adminLogs: "/admin/logs",
  adminLogsShift: "/admin/logs/shift",
  adminShiftPayments: "/admin/shift-payments",
  adminShiftPaymentsPayRates: "/admin/shift-payments/pay-rates",
  adminShiftPaymentsChangeLogs: "/admin/shift-payments/change-logs",
  adminDocument: "/admin/documents/:userId",
  adminUrgentShiftNcns: "/admin/urgentShiftsNcns",
  adminManageWorkplaceTypes: "/admin/workplace-types",
  adminManageQualifications: "/admin/qualifications",
} as const;

export function getLocation(
  name:
    | "root"
    | "enter"
    | "admin"
    | "myAccount"
    | "loginVerify"
    | "calendar"
    | "facilityOnboarding"
    | "facilityLiveWorkerETA"
    | "facilityUsers"
    | "facilityWorkerInstructions"
    | "facilityTimeclockSettings"
    | "facilityChat"
    | "facilityInvoiceHistory"
    | "facilityInvoiceUpcomingCharges"
    | "facilityInvoiceContractTerms"
    | "facilityProfile"
    | "facilitySettings"
    | "workersDirectory"
    | "workplaceBlockShifts"
    | "workplaceUsers"
    | "workers"
    | "agentPayroll"
    | "agentProof"
    | "agentStripePayouts"
    | "agentStripeTransfers"
    | "agentAttendance"
    | "agentProfile"
    | "agentPlacement"
    | "adminDashboard"
    | "adminTimeclock"
    | "adminProfile"
    | "adminAccounts"
    | "adminAccountsAgents"
    | "adminAccountsEmployees"
    | "adminAccountsInvocingByPeriod"
    | "adminAccountsInvocingByNumber"
    | "adminAccountsPostInvoiceDisputes"
    | "adminAccountsPreInvoiceDisputes"
    | "adminAccountsFacilities"
    | "adminAccountsRequirement"
    | "adminAccountsLocations"
    | "adminAccountsChangeLog"
    | "adminAccountsExclusions"
    | "adminLogs"
    | "adminLogsShift"
    | "adminShiftPayments"
    | "adminShiftPaymentsPayRates"
    | "adminShiftPaymentsChangeLogs"
    | "adminUrgentShiftNcns"
    | "adminManageWorkplaceTypes"
    | "adminManageQualifications"
    | "workplaceHireProfessionals"
    | "workplaceHireProfessionalDetails"
): Location;
export function getLocation(
  name: "enter",
  options?: { queryParams?: { agentId: string; url: string } }
): Location;
export function getLocation(
  name: "workplaceGetAccess",
  options?: { queryParams?: { "referral-code": string; "referral-intent"?: string } }
): Location;
export function getLocation(
  name: "workplaceReferrals",
  options?: { queryParams?: { referrerEmail: string } }
): Location;
export function getLocation(
  name: "workplaceReferAndEarn",
  options: { queryParams: { source: "banner" | "sidenav" } }
): Location;
export function getLocation(
  name: "agentDashboard" | "facilityDashboard",
  options?: { queryParams?: { date: string } }
): Location;
export function getLocation(
  name: "facilityChatWithAgent",
  options: { pathParams: { facilityId: string; agentId: string } | undefined }
): Location;
export function getLocation(
  name: "workplaceWorkerDetails" | "workersDirectoryWorkerDetails",
  options: { pathParams: { workerId: string } }
): Location;
export function getLocation(
  name: "document" | "workplaceUserDetails" | "adminDocument",
  options: { pathParams: { userId: string } }
): Location;
export function getLocation(
  name: "agentICAgreement",
  options: { pathParams: { workerId: string; icAgreementVersion: number } }
): Location;
export function getLocation(
  name: "workplaceSignContract",
  options: { pathParams: { workplaceId: string } }
): Location;
export function getLocation(
  name: "reference" | "adminInvoice" | "adminInvoicePdf" | "timesheet" | "timesheetPdf",
  options: { pathParams: { id: string } }
): Location;
export function getLocation(
  name: "timesheetRedirect",
  options: { pathParams: { invoiceNumber: number } }
): Location;
export function getLocation(
  name: "signatorySign",
  options: { pathParams: { facilityId: string; signatoryId: string } }
): Location;
export function getLocation(
  name: "workplaceWorkers",
  options: {
    queryParams:
      | Partial<{
          category: "favorite" | "upcoming" | "in_progress" | "past";
          search: string;
        }>
      | undefined;
  }
): Location;

export function getLocation(
  name: "facilitySettings",
  options: {
    queryParams: {
      tab: "calendar-settings" | "custom-settings";
      source?: "calendar-setting-click" | "tab-click";
    };
  }
): Location;

export function getLocation(
  name: "workplaceHireProfessionals",
  options: { queryParams: { source: "browse-professionals-alert" | "sidenav" } }
): Location;

export function getLocation(
  name: "workplaceHireProfessionalDetails",
  options: { pathParams: { placementCandidateId: string } }
): Location;

// TODO: Remove the overload mechanism and use PATH constants instead along with native react-router methods such as `generatePath`
export function getLocation(
  name: keyof typeof routes,
  {
    pathParams,
    queryParams,
  }:
    | Partial<{
        pathParams?: Record<string, unknown>;
        queryParams?: Record<string, unknown>;
      }>
    | undefined = {}
): Location {
  const pathname = routes[name] ?? "";
  const location: Location = {
    pathname,
  };

  if (pathParams) {
    const pathFunction = pathToRegexp.compile(pathname);
    location.pathname = pathFunction(pathParams);
  }
  if (queryParams) {
    const query = queryString.stringify(queryParams, {
      arrayFormat: "bracket",
    });
    location.search = query;
  }

  return location;
}
