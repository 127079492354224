import { Session } from "src/modules/interface";
import { getLocation } from "src/utils/routes";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";

export function evaluateWorkplaceToWorkplaceReferrals(
  ldFlags: LDFlagSet | undefined,
  user: Session["user"] | undefined,
  isFacility: boolean,
  admin: boolean
): boolean {
  const { workplaceToWorkplace } = ldFlags?.[CbhFeatureFlag.WORKPLACE_REFERRALS] ?? {};
  return (
    workplaceToWorkplace === true &&
    isFacility &&
    (admin || (user?.isEmailVerified === true && !user.isSuspicious))
  );
}

export enum WorkplaceReferralVariant {
  SHIFT_CREDIT = "shift_credit",
  SHIFT_CREDIT_AND_USER_BONUS = "shift_credit_and_user_bonus",
}

export enum ReferralBonusType {
  SHIFT_CREDIT = "SHIFT_CREDIT",
  USER_BONUS = "USER_BONUS",
}

export const DEFAULT_WORKPLACE_REFERRAL_INCENTIVE = 75;
export function fetchWorkplaceReferralVariantWithIncentive(
  ldFlags: LDFlagSet,
  user: Session["user"],
  isFacility: boolean,
  facilityType: string,
  admin: boolean
): { variant: WorkplaceReferralVariant; incentive: number } | null {
  const { workplaceToWorkplaceVariant, workplaceToWorkplaceIncentive } =
    ldFlags?.[CbhFeatureFlag.WORKPLACE_REFERRALS] ?? {};
  const isWorkplaceToWorkplace = evaluateWorkplaceToWorkplaceReferrals(
    ldFlags,
    user,
    isFacility,
    admin
  );
  if (isWorkplaceToWorkplace) {
    return {
      variant: (workplaceToWorkplaceVariant ||
        WorkplaceReferralVariant.SHIFT_CREDIT) as WorkplaceReferralVariant,
      incentive: (workplaceToWorkplaceIncentive?.[facilityType] ||
        workplaceToWorkplaceIncentive?.default ||
        DEFAULT_WORKPLACE_REFERRAL_INCENTIVE) as number,
    };
  }
  return null;
}

export function getReferralUrl(referralCode: string) {
  const path = getLocation("workplaceGetAccess", {
    queryParams: { "referral-code": referralCode },
  });
  return `${location.protocol}//${location.host}${path.pathname}?${path.search}`;
}

export function logReferAndEarnEvents({
  action,
  workplaceId,
  workplaceUserId,
  source,
}: {
  action: string;
  workplaceId: string;
  workplaceUserId: string;
  source?: string;
}) {
  logEvent(HCF_USER_EVENTS.WORKPLACE_REFER_AND_EARN, {
    action,
    workplaceId,
    workplaceUserId,
    source,
  });
}
