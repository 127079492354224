import { Text } from "@clipboard-health/ui-react";
import { Alert, AlertTitle, Button, Badge, Stack, Box } from "@mui/material";
import { useEffectOnce } from "react-use";
import { useLocalStorage } from "usehooks-ts";
import { HIRE_WORKERS_ALERT_VIEW_COUNT, HIRE_WORKERS_PAGE_VIEW_COUNT } from "../utils/constant";
import { useHistory } from "react-router-dom";
import { useIsHireProfessionalsEnabled } from "../useIsHireProfessionalsEnabled";

export function HireProfessionalsAlert() {
  const [hireWorkersViewCount] = useLocalStorage(HIRE_WORKERS_PAGE_VIEW_COUNT, 0);
  const [alertViewCount, setAlertViewCount] = useLocalStorage(HIRE_WORKERS_ALERT_VIEW_COUNT, 0);
  const history = useHistory();
  const shouldShowAlert = (hireWorkersViewCount ?? 0) <= 5 && (alertViewCount ?? 0) <= 10;

  const isHireProfessionalsEnabled = useIsHireProfessionalsEnabled();

  useEffectOnce(() => {
    if (shouldShowAlert && isHireProfessionalsEnabled) {
      setAlertViewCount((prev = 0) => prev + 1);
    }
  });

  if (!shouldShowAlert || !isHireProfessionalsEnabled) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Alert
        severity="info"
        action={
          <Button
            variant="contained"
            onClick={() =>
              history.push("/workplace/hire-professionals?source=browse-professionals-alert")
            }
          >
            Switch to Hire Professionals
          </Button>
        }
        icon={false}
        sx={{
          "& .MuiAlert-action": {
            alignItems: "center",
            marginRight: 0,
          },
        }}
      >
        <Stack direction="row" spacing={2.5} alignItems="center">
          <Badge
            color="info"
            badgeContent={
              <Text variant="caption" color="white" sx={{ fontSize: "0.5rem" }}>
                NEW
              </Text>
            }
            sx={{
              paddingLeft: 2,
            }}
          />
          <AlertTitle>Hire Professionals</AlertTitle>
        </Stack>
        <Text variant="body2">
          Hire qualified local professionals looking for permanent work near you.
        </Text>
      </Alert>
    </Box>
  );
}
