// We use redux in a contained manner to access the session until we
// have robust tools to do so. In practice, this will allow us to replace
// redux in a single place instead of having it scattered across the codebase.

// eslint-disable-next-line no-restricted-imports
import { useSelector } from "react-redux";
import { Session } from "../types";

interface SessionState {
  session: Session;
}

export function useSession(): Session {
  return useSelector<SessionState, Session>((state) => state.session);
}
