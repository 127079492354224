export const HIRE_WORKERS_PAGE_VIEW_COUNT = "hireWorkersPageViewCount";
export const HIRE_WORKERS_ALERT_VIEW_COUNT = "hireWorkersAlertViewCount";
export const TEMPORARY_RESOURCE_ACCESS_OTP = "temporary-resource-access-otp";
export const TEMPORARY_RESOURCE_ACCESS_EMAIL = "temporary-resource-access-email";
export const WHITELISTED_EMAILS = "placement-candidate-whitelisted-emails";
export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = [
  "CNA",
  "LVN",
  "RN",
  "Caregiver",
  "HHA",
  "CHHA",
];
