import { Modal } from "antd";
import { getErrorMessage } from "../../utils/errors";
import { StyledDivider } from "./styles";
import { SHIFT_INVITES_HELP_URL } from "./constants";
import { isDefined } from "@clipboard-health/util-ts";

export const handleWorkerRatingErrorMessageDisplay = (error: unknown) => {
  const isForbidden =
    typeof error === "object" && isDefined(error) && "status" in error && error.status === 403;
  return Modal.error({
    title: <b>{isForbidden ? "This professional cannot be rated" : "Error rating worker"}</b>,
    content: (
      <div data-testid="worker-reviews-modal-error-content">
        <div>{getErrorMessage(error, "There is an error when creating rating performance.")}</div>
        <StyledDivider />
        <div>
          Please use the{" "}
          <a href={SHIFT_INVITES_HELP_URL} target="_blank">
            shift invites
          </a>{" "}
          feature to schedule this professional for a shift at your facility
        </div>
      </div>
    ),
  });
};
