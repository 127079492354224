// Improvement: Replace these with dinero. Ref. https://www.notion.so/0017-Dinero-js-v2-Monetary-Values-2776ba6d01c641aeb7d374664d8ea994

/**
 * Given money in US dollars, format as dollars string.
 * For example, when given the number `52.341`, return `'$52.34'`
 * @param amountInDollars a number
 * @returns A string representing the amount in USD
 */
export function formatDollarsAsUsd(
  amountInDollars: number,
  options?: Intl.NumberFormatOptions
): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(amountInDollars);
}

/**
 * Given money in US cents, format as dollars string.
 * For example, when given the number `52`, return `'$0.52'`
 * @param amountInCents a number
 * @returns A string representing the amount in USD
 */
export function formatCentsAsUsd(
  amountInCents: number,
  options?: Intl.NumberFormatOptions
): string {
  return formatDollarsAsUsd(amountInCents / 100, options);
}
