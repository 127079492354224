import { useCallback } from "react";
import { useLocalStorage } from "react-use";

interface UseRateLimiterProps {
  actionKey: string;
  delayInMilliSeconds: number;
  maxCalls: number;
}

// Return a wrapper function that will execute the action if the rate limit is not exceeded
type UseRateLimiterResult = (props: {
  onRateLimitExceeded?: () => void;
  onRateLimitNotExceeded: () => void;
}) => void;

/**
 * A hook to rate limit or cap UI actions in React using localStorage.
 */
const useRateLimiter = (props: UseRateLimiterProps): UseRateLimiterResult => {
  const { actionKey, delayInMilliSeconds, maxCalls } = props;
  const key = `${actionKey}-useRateLimiter`;
  const [storedData, setStoredData] = useLocalStorage(key, {
    callCount: 0,
    lastCallTime: 0,
  });
  const rateLimitedCallbacks = useCallback(
    (props: { onRateLimitExceeded?: () => void; onRateLimitNotExceeded: () => void }) => {
      const now = Date.now();
      const { callCount, lastCallTime } = storedData ?? { callCount: 0, lastCallTime: 0 };

      if (now - lastCallTime > delayInMilliSeconds) {
        setStoredData({ callCount: 1, lastCallTime: now });
        props.onRateLimitNotExceeded();
        return;
      } else if (callCount < maxCalls) {
        setStoredData((prev) => ({
          callCount: prev?.callCount ?? 0 + 1,
          lastCallTime: now,
        }));
        props.onRateLimitNotExceeded();
        return;
      }

      props.onRateLimitExceeded?.();
    },
    [storedData, delayInMilliSeconds, maxCalls, setStoredData]
  );

  return rateLimitedCallbacks;
};

export default useRateLimiter;
