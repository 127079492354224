import { useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { TimeValidationError } from "@mui/x-date-pickers";
import { utcToZonedTime } from "date-fns-tz";
import { isDefined } from "@clipboard-health/util-ts";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";

import { DefaultShiftTimes } from "@src/containers/facilityDashboard/ShiftCalendar/api";
import {
  convertShiftTimeToDateTime,
  normalizeShiftEndTime,
} from "@src/containers/facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { ShiftName } from "@src/appV2/ShiftBlock/types/shift";
import { ShiftInviteInfo } from "./ShiftInviteReview";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { differenceInHours, isSameDay } from "date-fns";
import { Text } from "@clipboard-health/ui-react";
import { shiftTypeColorMap } from "./types";

export interface ShiftTypeTimeSelectionProps {
  shiftType: ShiftName;
  onChange?: (value: SelectedShiftInfo) => void;
  shiftTime: DefaultShiftTimes[keyof DefaultShiftTimes];
  selectedDate?: Date;
  shiftInfo: ShiftInviteInfo | undefined;
}

export interface SelectedShiftInfo {
  shiftType: ShiftName;
  start?: Date;
  end?: Date;
  isSelected: boolean;
}

export function ShiftTypeTimeSelection(props: ShiftTypeTimeSelectionProps) {
  const { shiftType, onChange, shiftTime, selectedDate, shiftInfo } = props;
  const { facilityTimeZone } = useShiftInviteContext();
  const { startTime, endTime } = isDefined(shiftInfo)
    ? { startTime: shiftInfo.start, endTime: shiftInfo.end }
    : convertShiftTimeToDateTime({ shiftTime, startDate: selectedDate });

  const [timeError, setTimeError] = useState<TimeValidationError | "invalidRange">();
  const isSelected = isDefined(shiftInfo);
  function onShiftInfoChange(params: Partial<SelectedShiftInfo>) {
    onChange?.({
      shiftType,
      start: startTime,
      end: endTime,
      isSelected,
      ...params,
    });
  }

  const duration = differenceInHours(endTime, startTime, { roundingMethod: "ceil" });
  const shiftCardColor = (theme) =>
    isSelected ? theme.palette[shiftTypeColorMap[shiftType]].main : theme.palette.grey[500];
  return (
    <Stack sx={{ position: "relative" }}>
      <Box
        sx={{
          padding: 1,
          paddingTop: 2,
          paddingBottom: 2,
          border: "1px solid",
          borderRadius: 1,
          borderColor: shiftCardColor,
          boxShadow: (theme) => theme.shadows[3],
        }}
      >
        <Stack spacing={2} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected}
                color={shiftTypeColorMap[shiftType]}
                onChange={() => {
                  if (isSelected) {
                    setTimeError(undefined);
                    onShiftInfoChange({ isSelected: false, start: startTime, end: endTime });
                  }
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <Text bold color={shiftCardColor}>
                {shiftType.toUpperCase()}
              </Text>
            }
            sx={{ minWidth: "80px" }}
          />
          <MultiInputTimeRangeField
            value={[startTime, endTime]}
            slotProps={{
              textField: ({ position }) => ({
                label:
                  position === "start"
                    ? "Start time"
                    : `End time${isSameDay(startTime, endTime) ? "" : " (next day)"}`,
                size: "small",
              }),
              root: { spacing: 1 },
            }}
            onChange={([start, end]) => {
              const newEnd =
                isDefined(start) && isDefined(end) ? normalizeShiftEndTime(start, end) : end;
              onShiftInfoChange({ start: start ?? undefined, end: newEnd ?? undefined });
            }}
            disableIgnoringDatePartForTimeValidation={true}
            minTime={isSelected ? utcToZonedTime(new Date(), facilityTimeZone) : undefined}
            onError={([startError, endError]) => {
              setTimeError(startError ?? endError);
            }}
            disabled={!isSelected}
            formatDensity="dense"
          />
        </Stack>
      </Box>
      {!isSelected && (
        <Box
          data-testid={`select-shift-type-${shiftType}`}
          onClick={() => onShiftInfoChange({ isSelected: true, start: startTime, end: endTime })}
          sx={{
            position: "absolute",
            inset: 0,
            zIndex: 1,
            backgroundColor: "transparent",
            borderRadius: 1,
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
          }}
        />
      )}
      {timeError === "minTime" && <Text color="error">Shift can't start in the past</Text>}
      {duration > 17 && <Text>This shift is {duration} hours</Text>}
    </Stack>
  );
}
