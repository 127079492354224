import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { logEvent } from "@src/appV2/lib/analytics";
import { ActiveTab } from "src/containers/workers/logs";

export enum WhoCalledChatModal {
  /**
   * If the component is rendered, when we are calling it from shift-details screen, select this value.
   */
  SHIFT_DETAILS = "shiftDetails",
  /**
   * If the component is rendered, when we are calling it from chat channel screen, set this value.
   */
  CHAT_CHANNEL = "chatChannel",
  /**
   * This property is used, in case when the chat modal is opened from worker details page.
   */
  WORKER_DETAILS = "workerDetails",
  /**
   * If the component is rendered, when we are calling it from workers screen, set this value.
   */
  WORKERS_PAGE = "workersPage",
  /**
   * This property is used, in case when the chat modal is opened from browse Professional details page.
   */
  BROWSE_WORKER_DETAILS = "browseWorkerDetails",
  /**
   * If the component is rendered, when we are calling it from Browse Professionals screen, set this value.
   */
  BROWSE_PROFESSIONALS_PAGE = "browseProfessionalsPage",
  /**
   * If the component is rendered, when we are calling it from available workers section, set this value.
   */
  AVAILABLE_WORKERS_SECTION = "availableWorkersSection",
}

type CommonLogData = {
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
  activeTab?: ActiveTab;
  location: WhoCalledChatModal;
};

export function logChatOpen({
  workerId,
  workplaceId,
  workplaceUserId,
  activeTab,
  location,
}: CommonLogData) {
  logEvent(HCF_USER_EVENTS.CHAT_OPENED, {
    workerId,
    workplaceId,
    workplaceUserId,
    activeTab,
    location,
  });
}
