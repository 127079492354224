import { useEffect, useState } from "react";

export const DEFAULT_DEBOUNCE_RATE_IN_MS = 500;
interface DebounceProps<T> {
  value: T;
  // Delay in milliseconds - default is 500 msec.
  debounceTimeInMs?: number;
  // If provided, onUpdateValue must be memoized in `useCallback`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateValue?: (value: T) => any;
}

/**
 * Extended from https://usehooks-ts.com/react-hook/use-debounce
 * Debounce a value to meter its rate of changes.
 */
export function useDebounce<T>(props: DebounceProps<T>): T {
  const { value, debounceTimeInMs = DEFAULT_DEBOUNCE_RATE_IN_MS, onUpdateValue } = props;
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      onUpdateValue?.(value);
    }, debounceTimeInMs);

    return () => {
      clearTimeout(timer);
    };
  }, [debounceTimeInMs, onUpdateValue, value]);

  return debouncedValue;
}
