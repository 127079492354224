import { LicenseDetails } from "./license.types";
import { WorkerDocument } from "./workerDocument.types";

export enum DocumentType {
  DOCUMENT = "DOCUMENT",
  LICENSE = "LICENSE",
}

export enum DownloadRequestStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum PageActions {
  VIEW = "view",
  DOWNLOAD = "download",
  PRINT = "print",
  DOWNLOAD_SELECTED = "downloadSelected",
  PRINT_SELECTED = "printSelected",
}

export enum ViewType {
  LIST = "list",
}

export enum DownloadResultType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface DocumentWithId {
  document: WorkerDocument | LicenseDetails;
  documentId: string;
  documentType: DocumentType;
}

export interface DocumentListProps {
  documents: WorkerDocument[];
  licenses: LicenseDetails[];
  userId: string;
}
