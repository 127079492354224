import { isValid, parseISO } from "date-fns";

const PAY_RATE_BOOST_PERCENTAGE_OF_CHARGE = 0.75;
export const VALID_CHARGE_REGEX = /^(\d{0,2}(\.\d{0,2})?)?$/;

interface GetShiftInfoKeyParams {
  date: Date;
  name: string;
}

export function getShiftInfoKey(getShiftInfoKeyParams: GetShiftInfoKeyParams): string {
  const { date, name } = getShiftInfoKeyParams;
  return `${name}-${date.toISOString()}`;
}

export function parseValidDateOrDefaultToToday(date: string): Date {
  return isValid(parseISO(date)) ? parseISO(date) : new Date();
}

/**
 * Calculate the pay boost for a given charge
 * @param charge - The charge to calculate the pay boost for, at most 2 decimal digits
 * @returns The pay boost for the given charge
 */
export function calculatePayBoost(charge: string): string {
  const numCharge = parseFloat(charge);
  if (Number.isNaN(numCharge) || numCharge === 0 || !VALID_CHARGE_REGEX.test(charge)) {
    return "-";
  }

  // To avoid JavaScript imprecision, we convert the charge and percentage to integers
  // before multiplying. Note this relies on charge and payBoostPercentage having
  // 2 or fewer decimal digits.
  const numChargeInt = Math.round(numCharge * 100);
  const payBoostPercentageInt = Math.round(PAY_RATE_BOOST_PERCENTAGE_OF_CHARGE * 100);
  const moneyInt = Math.round((numChargeInt * payBoostPercentageInt) / 100);
  return (moneyInt / 100).toFixed(2);
}
