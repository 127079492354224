import { Button, CircularProgress, Stack } from "@mui/material";
import { DataGridPro, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid-pro";
import { format, isSameDay } from "date-fns";
import pluralize from "pluralize";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { ShiftInviteRequestParams, useCreateShiftInvite } from "./useCreateShiftInvite";
import { useSelector } from "react-redux";
import { SessionType } from "@src/modules/interface";
import { UseModalState, useModalState } from "@clipboard-health/ui-react";
import { zonedTimeToUtc } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useState } from "react";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { NearbyWorkerSearchResult } from "./Autocomplete/useGetNearbyWorkers";
import { isDefined } from "@clipboard-health/util-ts";
import { message as toast } from "antd";
import { MultiShiftInviteErrorDialog } from "./Dialogs/MultiShiftInviteErrorDialog";
import { Text } from "@clipboard-health/ui-react";
import { calculatePayBoost } from "./utils";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

export enum Severity {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export interface ShiftInviteInfo {
  start: Date;
  end: Date;
  name: string;
  details?: { message: string; level: Severity };
  selectedDate?: Date;
}

export interface GroupShiftInvite {
  workplaceId: string;
  agentReq: string;
  tmz: string;
  postShiftToMarketplace: boolean;
}

export type ShiftInviteReviewProps = {
  workerType: string;
  modalState: UseModalState;
  setActiveStep: (step: number) => void;
  selectedWorker?: NearbyWorkerSearchResult;
};

type SendInviteParams = ShiftInviteRequestParams & {
  shiftInfoKey: string;
};

export function ShiftInviteReview(props: Readonly<ShiftInviteReviewProps>) {
  const { workerType, modalState, setActiveStep, selectedWorker } = props;
  const { user, admin } = useSelector((state: SessionType) => state.session);

  const { mutateAsync: createShiftInvite, isLoading: isSaving } = useCreateShiftInvite();
  const {
    groupInviteSharedData,
    multipleShiftInfo,
    multipleShiftValidationDetails,
    facilityTimeZone,
    customChargeRateBoost,
  } = useShiftInviteContext();
  const excludedLevelsForPreSelect = [Severity.ERROR, Severity.WARN];
  const initialSelectedShiftInvites = [...multipleShiftInfo.keys()].filter(
    (shiftInfoKey) =>
      !excludedLevelsForPreSelect.includes(
        multipleShiftValidationDetails.get(shiftInfoKey)?.level ?? Severity.INFO
      )
  );
  const [selectedInvites, setSelectedInvites] = useState<GridRowId[]>(initialSelectedShiftInvites);
  const [failedInvites, setFailedInvites] = useState<ShiftInviteInfo[]>([]);
  const multiShiftInviteErrorDialogState = useModalState();

  const columns: GridColDef[] = [
    {
      field: "shift",
      headerName: "Shift",
      minWidth: 400,
      resizable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 400,
      valueGetter: (params) => params.value,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const sortedShiftInfoEntries = Array.from(multipleShiftInfo.entries()).sort(
    (shiftInfoA, shiftInfoB) => {
      return shiftInfoA[1].start.getTime() - shiftInfoB[1].start.getTime();
    }
  );

  const rows: GridRowsProp = sortedShiftInfoEntries.map(([shiftInfoKey, invite]) => {
    const shiftTime = `${format(invite.start, "MM/dd/yy")} ${invite.name?.toUpperCase()} ${format(
      invite.start,
      "hh:mm"
    )} -  
    ${
      isSameDay(invite.start, invite.end)
        ? format(invite.end, "hh:mm")
        : format(invite.end, "hh:mm a (MM/dd/yy)")
    }`;
    const validationDetails = multipleShiftValidationDetails.get(shiftInfoKey);
    return {
      id: shiftInfoKey,
      shift: shiftTime,
      details: validationDetails?.message ?? "",
      enabled: validationDetails?.level !== Severity.ERROR,
    };
  });

  const segmentProperties = {
    workerType: groupInviteSharedData.agentReq,
    ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
    ...groupInviteSharedData,
  };

  async function sendInvite(params: SendInviteParams) {
    const { shiftInfoKey, shiftInviteRequest, queryParams } = params;
    const shiftValidationDetails = multipleShiftValidationDetails.get(shiftInfoKey);
    try {
      await createShiftInvite({ shiftInviteRequest, queryParams });
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "GROUP_INVITE_SENT",
        groupId: shiftInviteRequest.groupId,
        skipFacilityRequiredDocumentCheck: isDefined(
          shiftValidationDetails?.workerMissingFacilityDocuments
        ),
        workerMissingDocuments: shiftValidationDetails?.workerMissingFacilityDocuments,
        isOverworkingShiftsAllowed: shiftValidationDetails?.isOverworkingShift,
        ...segmentProperties,
      });
      return { invite: shiftInviteRequest };
    } catch (error: unknown) {
      logError("Error while creating the group shift invite", {
        error,
        metadata: {
          invite: { shiftInviteRequest, queryParams },
        },
      });

      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "GROUP_INVITE_SEND_ERROR",
        groupId: shiftInviteRequest.groupId,
        ...segmentProperties,
      });

      return { invite: shiftInviteRequest, error };
    }
  }

  return (
    <>
      <DataGridPro
        rows={rows}
        columns={columns}
        checkboxSelection
        rowSelectionModel={selectedInvites}
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectedInvites(newSelectionModel);
        }}
        pagination
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        getRowClassName={(params) => (params.row.enabled ? "" : "Mui-disabled")}
        isRowSelectable={(params) => params.row.enabled}
        disableRowSelectionOnClick
        localeText={{
          footerTotalRows: "Total Invite Count",
          footerRowSelected(count) {
            return `You have selected ${count} ${pluralize("invite", count)}`;
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Invites per page",
          },
        }}
        sx={{
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: "#F5F5F5",
            color: "#000000",
          },
          ".MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
      />
      {selectedInvites.length > 0 &&
        isDefined(customChargeRateBoost) &&
        customChargeRateBoost > 0 && (
          <Text>
            By sending the selected invites, I agree to boost the pay by{" "}
            {formatDollarsAsUsd(parseFloat(calculatePayBoost(customChargeRateBoost.toString())))}{" "}
            for this professional and be charged an additional{" "}
            {formatDollarsAsUsd(customChargeRateBoost)} per invite. This adjustment will be
            reflected on my invoice after these shifts are completed.
          </Text>
        )}
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          onClick={() => {
            setActiveStep(1);
            logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
              action: "GROUP_INVITE_BACK_TO_DETAILS_SECTION",
              ...segmentProperties,
            });
          }}
          variant="outlined"
          disabled={isSaving}
          startIcon={<ArrowBackIosNewOutlinedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={isSaving || selectedInvites.length === 0}
          startIcon={isSaving && <CircularProgress size={18} sx={{ color: "inherit" }} />}
          onClick={async () => {
            const dataSource = new Map(selectedInvites.map((id) => [id, true]));

            const groupId = uuidv4();

            const createShiftInviteRequests = [...multipleShiftInfo.entries()]
              .filter(([shiftInfoKey, _invite]) => {
                return dataSource.has(shiftInfoKey);
              })
              .map(([shiftInfoKey, invite]) => {
                const shiftInviteRequest = {
                  workerId: selectedWorker?.workerId ?? "",
                  workplaceId: groupInviteSharedData.workplaceId,
                  shiftDetails: {
                    start: zonedTimeToUtc(invite.start, facilityTimeZone),
                    end: zonedTimeToUtc(invite.end, facilityTimeZone),
                    agentReq: workerType,
                    name: invite.name,
                    tmz: facilityTimeZone,
                    customChargeRateBoost,
                  },
                  postShiftToMarketplace: groupInviteSharedData.postShiftToMarketplace,
                  groupId,
                };
                return sendInvite({
                  shiftInviteRequest,
                  queryParams: {
                    isOverworkingShiftsAllowed: true,
                    skipFacilityRequiredDocumentCheck: true,
                  },
                  shiftInfoKey,
                });
              });

            const results = await Promise.allSettled(createShiftInviteRequests);

            const failed = results.reduce((acc, result) => {
              if (result.status === "fulfilled" && isDefined(result.value.error)) {
                acc.push(result.value.invite.shiftDetails);
              }
              return acc;
            }, [] as ShiftInviteInfo[]);

            if (failed.length > 0) {
              setFailedInvites(failed);
              logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
                action: "GROUP_INVITE_SENT_PARTIAL_SUCCESSFUL",
                totalInvites: createShiftInviteRequests.length,
                failedInvites: failed.length,
                groupId,
                ...segmentProperties,
              });
              multiShiftInviteErrorDialogState.openModal();
              return;
            }

            toast.success(
              `You sent ${createShiftInviteRequests.length} shift invites to ${selectedWorker?.fullName}. You can view the invitations on your calendar.`
            );

            logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
              action: "GROUP_INVITE_SENT_SUCCESSFUL",
              totalInvites: createShiftInviteRequests.length,
              groupId,
              ...segmentProperties,
            });

            modalState.closeModal();
          }}
        >
          Send All Selected Invites
        </Button>
      </Stack>
      <MultiShiftInviteErrorDialog
        modalState={multiShiftInviteErrorDialogState}
        failedInvites={failedInvites}
        onClose={() => {
          multiShiftInviteErrorDialogState.closeModal();
          modalState.closeModal();
        }}
      />
    </>
  );
}
